import Vue from 'vue'
import App from './App.vue'
import Element from "element-ui";
import plugins from "./plugins"; // plugins
import store from "./store";

import {
  resetForm,
} from "@/utils/ruoyi";
Element.Dialog.props.closeOnClickModal.default = false; //禁止点击遮罩关闭
import "./assets/styles/element-variables.css";
import '@/assets/styles/iconfont/iconfont.css'
Vue.config.productionTip = false

import router from "./router";
Vue.prototype.resetForm = resetForm;

import "./permission"; // permission control

Vue.use(plugins);
Vue.use(Element, {
  size:"medium", // set element-ui default size
});

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
