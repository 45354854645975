import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken,getUserid,setUserid,removeUserid,removePlayURL,setPlayURL,getPlayURL,getPlayStaus,setPlayStaus,removePlayStaus } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    userid:getUserid(),
    PlayURL:getPlayURL(),
    PlayStaus:getPlayStaus()?getPlayStaus():false,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_DeptId: (state, deptId) => {
      state.deptId = deptId
    },
    SET_ParentId:(state, parentId) => {
      state.parentId = parentId
    },
    SET_Ancestors:(state, ancestors) => {
      state.ancestors = ancestors
    },
    SET_Userid:(state, ancestors) => {
      state.userid = ancestors
    },
    SET_PlayURL:(state, ancestors) => {
      state.PlayURL = ancestors
    },
    SET_PlayStaus:(state, ancestors) => {
      state.PlayStaus = ancestors
    },
  },

  actions: {
    ChangePlayStaus({commit},staus){
      commit('SET_PlayStaus', staus)
      setPlayStaus(staus)
    },
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          setToken(res.token)
          setUserid(res.data.id)
          setPlayURL(res.data.play_url)
          commit('SET_TOKEN', res.token)
          commit('SET_Userid', res.data.id)
          commit('SET_PlayURL', res.data.play_url)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          const user = res.user
          const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
          if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles)
            commit('SET_PERMISSIONS', res.permissions)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          commit('SET_NAME', user.userName)
          commit('SET_AVATAR', avatar)
          commit('SET_DeptId', user.deptId)
          commit('SET_ParentId', user.dept.parentId)
          commit('SET_Ancestors', user.dept.ancestors)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        // logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_Userid', '')
          commit('SET_PlayURL', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          commit("SET_PlayStaus", false);
          removeToken()
          removeUserid()
          removePlayURL()
          resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_Userid', '')
        removeUserid()
        resolve()
      })
    }
  }
}

export default user
