<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app{
  height: 100%;
}
html,body {
  height: 100%;
  box-sizing: border-box;
}

#app {
  height: 100%;
}

</style>
