import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const userId = 'userId'
const play_url = 'play_url'
const play_staus = 'play_staus'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getUserid() {
  return Cookies.get(userId)
}

export function setUserid(token) {
  return Cookies.set(userId, token)
}

export function removeUserid() {
  return Cookies.remove(userId)
}
export function getPlayURL() {
  return Cookies.get(play_url)
}

export function setPlayURL(token) {
  return Cookies.set(play_url, token)
}

export function removePlayURL() {
  return Cookies.remove(play_url)
}
export function getPlayStaus() {
  return Cookies.get(play_staus)
}

export function setPlayStaus(token) {
  return Cookies.set(play_staus, token)
}

export function removePlayStaus() {
  return Cookies.remove(play_staus)
}
