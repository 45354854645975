import router from "./router";
import store from "./store";
import { Message } from "element-ui";

import { getUserid } from "@/utils/auth";
import { isRelogin } from "@/utils/request";


const whiteList = ["/login",];

router.beforeEach((to, from, next) => {
  if (getUserid()) {
    to.meta.title && store.dispatch("settings/setTitle", to.meta.title);
    /* has token*/
    if (to.path === "/login") {
      next();
    } else {
        next();
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
    }
  }
});

router.afterEach(() => {
  // document.title = JSON.parse(localStorage.getItem("allocation_item")).title;
  // NProgress.done();
});
